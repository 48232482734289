<script setup lang="ts">
import { useCompany } from '~/composables/useCompany';
const { company } = await useCompany();

useHead({
  titleTemplate: `%s | ${company.name}`,
  meta: [
    {
      name: 'description',
      content: `${company.name}の居住者向けサポートサイトです、よくあるご質問、メール電話でのお問い合わせはこちらから`,
    },
  ],
});
</script>

<template>
  <div :class="company.colorType">
    <Header :logo-url="company.logoUrl" />
    <main class="pt-4">
      <slot />
    </main>
    <Footer :company-name="company.name" :company-term-url="company.termUrl" />
  </div>
</template>
