<script setup lang="ts">
import { useRuntimeConfig } from '#app';

type Props = {
  companyName: string;
  companyTermUrl?: string;
};

withDefaults(defineProps<Props>(), {
  companyTermUrl: '',
});

const config = useRuntimeConfig();
const environment = config.public.appEnv;
const termsLink =
  environment !== 'prd'
    ? 'https://dev-image.relo-webtop.jp/wp-content/uploads/2024/10/WebTop%E5%88%A9%E7%94%A8%E8%A6%8F%E7%B4%84_20240930%E7%AD%96%E5%AE%9A.pdf'
    : 'https://image.relo-webtop.jp/wp-content/uploads/2024/10/WebTop%E5%88%A9%E7%94%A8%E8%A6%8F%E7%B4%84_20240930%E7%AD%96%E5%AE%9A.pdf';
</script>

<template>
  <footer class="border-t border-border py-4 text-center md:py-8">
    <div class="mb-8 flex flex-col gap-y-4 md:flex-row md:justify-center">
      <NuxtLink
        class="text-xs underline md:pr-4"
        :to="termsLink"
        target="_blank"
        rel="noopener noreferrer"
        >本Webサイト利用規約</NuxtLink
      >
      <!-- 動的に表示 -->
      <NuxtLink
        v-if="companyTermUrl"
        class="text-xs underline md:border-l md:border-border md:pl-4"
        :to="companyTermUrl"
        target="_blank"
        rel="noopener noreferrer"
        >{{ companyName }}利用規約</NuxtLink
      >
    </div>
    <small class="text-xs">Powered by Relo Create</small>
  </footer>
</template>
