<script setup lang="ts">
import IconComment from '~icons/icons/comment';
import IconInformation from '~icons/icons/information';

type Props = {
  logoUrl: string;
};
defineProps<Props>();

const route = useRoute();
// yupバリデかけようかな
const companySlug = route.params.companySlug.toString();
</script>

<template>
  <header
    class="container flex items-center justify-between gap-x-2 py-2 md:py-4"
  >
    <NuxtLink :to="`/${companySlug}`">
      <img
        class="h-[60px] w-auto object-contain md:h-20"
        :src="logoUrl"
        alt="企業ロゴ"
        loading="lazy"
      />
    </NuxtLink>
    <nav
      class="flex shrink-0 items-center gap-x-2 font-medium md:gap-x-8 md:font-bold"
    >
      <NuxtLink
        class="flex flex-col items-center gap-y-1 text-2xs text-black md:flex-row md:gap-x-1 md:text-base"
        :to="`/${companySlug}/news`"
      >
        <IconInformation width="24" height="24" class="size-6 fill-black" />
        お知らせ
      </NuxtLink>
      <NuxtLink
        class="flex flex-col items-center gap-y-1 text-2xs text-black md:flex-row md:gap-x-1 md:text-base"
        :to="`/${companySlug}#top-faq`"
      >
        <IconComment width="24" height="24" class="size-6 fill-black" />
        <p class="hidden md:inline-block">よくあるお問い合わせ</p>
        <p class="inline-block uppercase md:hidden">faq</p>
      </NuxtLink>
    </nav>
  </header>
</template>
